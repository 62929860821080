<template>
  <validation-observer rules="required" v-slot="{ handleSubmit }">
  <RightSideBar
    v-if="isOpen"
    :loading="isLoading"
    :disabled="isLoading"
    @submit="handleSubmit(onSubmit)"
    submit="Submit"
    @close="onClose"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        UnSuspend Employee
      </p>
    </template>
    <template v-slot:subtitle>
      <div class="flex flex-col justify-start pb-5">
        <p class="font-semibold text-sm text-darkPurple mb-2">Employee Details</p>
        <div class="flex justify-start items-start gap-5">
          <div class="w-44 h-32 rounded-md text-blueCrayola font-normal">
              <img class="w-44 h-32 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
              <div v-else class="h-32 border rounded-md flex justify-center items-center">
              <span style="font-size:60px">
                {{ $getInitials(`${employeeInfo.fname || ''} ${employeeInfo.lname || ''}`) }}
              </span>
              </div>
            </div>
          <div class="flex flex-col justify-start items-start gap-2 w-full">
            <div class="w-full flex flex-col justify-start items-start gap-2">
              <h4 class="font-bold text-lg leading-5 text-darkPurple">
                {{ employeeInfo.fname }} {{ employeeInfo.lname }}
              </h4>
              <div class="flex flex-col justify-start items-center w-full gap-0">
                <div class="flex justify-start items-start w-full">
                  <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                    Function:
                  </p>
                  <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                  <span v-if="employeeInfo.orgFunction">
                    {{ employeeInfo.orgFunction.name }}
                  </span>
                  <span v-else>-</span>
                  </p>
                </div>
                <div class="flex justify-start items-start w-full">
                  <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                    Designation:
                  </p>
                  <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                  <span v-if="employeeInfo.userDesignation">
                      {{ employeeInfo.userDesignation.name }}
                  </span>
                  <span v-else>-</span>
                  </p>
                </div>
                <div class="flex justify-start items-start w-full">
                  <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                    Job Level:
                  </p>
                  <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.userLevel">
                      {{ employeeInfo.userLevel.name }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="flex justify-start items-start w-full">
                  <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                    Office Location:
                  </p>
                  <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                  <span v-if="employeeInfo.employeeOffice">
                      {{ employeeInfo.employeeOffice.name }}
                  </span>
                  <span v-else>-</span>
                  </p>
                </div>
                <div class="flex justify-start items-start w-full">
                  <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                    Reporting to
                  </p>
                  <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                  <span v-if="employeeInfo.userReportingTo">
                      {{ employeeInfo.userReportingTo.fname }} {{ employeeInfo.userReportingTo.lname }}
                  </span>
                  <span v-else>-</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:default>
      <div class="flex flex-col justify-start divide-y divide-dashed divide-romanSilver">
        <div class="flex-flex-col justify-start gap-5 pb-5">
          <h3 class="font-bold text-lg text-darkPurple leading-5">
            Suspension Details
          </h3>
          <div class="flex flex-col justify-start gap-1 mt-2">
            <div class="flex flex-row justify-start items-center">
              <p class="w-1/2 font-bold _text-10 uppercase text-romanSilver leading-5">
                start date:
              </p>
              <p class="w-1/2 font-semibold text-sm text-jet leading-5">
                <span v-if="payload.startDate">
                   {{ $DATEFORMAT(new Date(payload.startDate), "MMMM dd, yyyy") }}
                </span>
              </p>
            </div>
            <div class="flex flex-row justify-start items-center">
              <p class="w-1/2 font-bold _text-10 uppercase text-romanSilver leading-5">
                End Date date:
              </p>
              <p class="w-1/2 font-semibold text-sm text-jet leading-5">
               <span v-if="payload.isIndefinite">Indefinite Suspension</span>
               <span v-else-if="payload.endDate">
                 {{ $DATEFORMAT(new Date(payload.endDate), "MMMM dd, yyyy") }}
               </span>
               <span v-else>N/A</span>
              </p>
            </div>
            <div class="flex flex-row justify-start items-center">
              <p class="w-1/2 font-bold _text-10 uppercase text-romanSilver leading-5">
                employee deactived on payroll:
              </p>
              <p class="w-1/2 font-semibold text-sm text-jet leading-5">
                <span v-if="payload.payrollStatus === 'inactive'">Yes</span>
                <span v-if="payload.payrollStatus === 'active'">No</span>
                <span v-if="!payload.payrollStatus">N/A</span>
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-start gap-5 pt-4">
          <div class="text-sm text-jet leading-5">
            <combobox
              placeholder="--Type or Select--"
              label="Reason for unsuspension"
              :items="reasonsOptions"
              v-model="payload.reason"
              variant="w-full"
            />
          </div>
          <div class="">
            <label name="comment" class="date-label">
              Comment
            </label>
            <c-textarea
              placeholder="--Select--"
              variant="w-full"
              :col="50"
              type="textarea"
              name="comment"
              v-model="payload.comment"
            />
          </div>
          <div>
            <template>
              <div class="cursor-pointer flex justify-end" @click="isOpenAttachment = true" v-feature-request>
                <div class="flex justify-center items-center gap-2 px-3 py-2 hover:bg-ghostWhite rounded-md">
                  <Icon icon-name="icon-plus" size="xs" class="text-flame" />
                  <p class="font-semibold text-base leading-5 text-flame">Upload Attachment</p>
                </div>
              </div>
            </template>
            <template v-if="isOpenAttachment">
              <div class="overlayer flex flex-wrap justify-center items-center">
                <Card class="bg-white relative p-4 flex flex-col justify-end items-end gap-2">
                  <div @click="isOpenAttachment = false">
                    <Icon
                      icon-name="close"
                      size="xs"
                      class="text-darkPurple cursor-pointer"
                    />
                  </div>
                  <file-upload />
                </Card>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </RightSideBar>
  </validation-observer>
</template>

<script>
import * as _ from 'lodash'
import { format } from "date-fns"
import { ValidationObserver } from "vee-validate"
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Combobox from "@scelloo/cloudenly-ui/src/components/combobox";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import FileUpload from '@scelloo/cloudenly-ui/src/components/file-upload';
import RightSideBar from "@/components/RightSideBar";

export default {
components: {
  ValidationObserver,
  FileUpload,
  RightSideBar,
  Card,
  CTextarea,
  Combobox
},
data() {
  return {
    isLoading: true,
    isOpen: false,
    isOpenAttachment: false,
    employeeInfo: {},
    suspensionInfo: {},
    reasonsOptions: [],
    payload: {
      userId: '',
      reason: null,
      comment: '',
      startDate: '',
      endDate: '',
      payrollStatus: '',
      effectiveDate: '',
    },
  }
},
methods: {
  toggle(payload){
    this.payload = {
      ...this.payload,
      suspendId: payload.id,
      userId: payload.suspension.userId,
      startDate: payload.suspension.startDate,
      endDate: payload.suspension.endDate,
      payrollStatus: payload.payrollStatus,
      isIndefinite: payload.suspension.indefinite,
    }
    this.employeeInfo = payload
    this.isOpen = true
  },
  onClose(){
    this.isOpen = false
    this.isLoading = false
    _.assign(this.$data.payload, this.$options.data().payload)
  },
  async onSubmit(){
    this.isLoading = true
    const findReasonIndex = _.find(this.reasonsOptions,
      reason => reason.id === this.payload.reason
    )
    if(!_.size(_.keys(findReasonIndex))){
      await this.$_suspensionReason({orgId: this.$orgId, reason: this.payload.reason})
      .then(({ data }) => {this.payload.reason = data.reason.id})
      .catch((error) => this.$toasted.error(error.message, {duration: 3000}))
    }

    const payload = {
      orgId: this.$orgId,
      userId: this.payload.userId,
      comments: this.payload.comment,
      reasonId: this.payload.reason,
      suspendId: this.payload.suspendId,
      effectiveDate: format(new Date(), "yyyy-MM-dd")
    }

    await this.$_unSuspendOrgEmployee(payload).then(({ data }) => {
      this.$toasted.success(data.message, {duration: 3000})
      this.$emit('success', data)
      this.onClose()
    }).catch((error) => {
      this.$toasted.error(error.message, {duration: 3000})
      this.isLoading = false
    })

  },
  async getSuspensionReason(){
    await this.$_getSuspensionReason().then(({ data }) => {
      this.reasonsOptions = data.reasons?.map(reason => ({
        ...reason, value: reason.reason
      }))
    })
  },
},
watch:{
  async isOpen(value){
    if(value){
      this.isLoading = true
      await this.getSuspensionReason()
      this.isLoading = false
    }
  }
}
};
</script>

<style scoped>
._font-10 {
font-size: 10px !important;
}
._text-10 {
font-size: 10px !important;
}
</style>
<style>
.rightSidebar {
width: 520px !important;
}
</style>
